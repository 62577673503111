<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const ssoUrl = computed(() => {
    let ssoUrl = import.meta.env.VUE_APP_SSO_URL

    if (import.meta.env.PROD) {
        const ssoHost = `${window.location.protocol}//rest.${window.location.host}`
        ssoUrl = `${ssoHost}/v1/public/sso/login/saml/?idp=default`
    }
    let additionalParams = `&nocache=${Math.random().toString().split('.')[1]}`
    if (localStorage.getItem('VUE_APP_SSO_URL_NEXT')) {
        additionalParams += `&next=${localStorage.getItem('VUE_APP_SSO_URL_NEXT')}`
    }
    return (localStorage.getItem('VUE_APP_SSO_URL') || ssoUrl) + additionalParams
})
</script>

<template>
    <div
        class="sso-button"
    >
        <v-btn
            :href="ssoUrl"
            color="primary"
            class="sso-button__button"
            id="sso_sign_in_button"
        >
            {{ t('login.sso_link') }}
        </v-btn>
    </div>
</template>

<style>
    .login-layout-form {
        &__inner:has(.sso-button) {
            margin-block-start: 10em!important;
        }
    }
    .sso-button {
        margin-block-start: 5em;

        &__button {
            inline-size: 100%;
            block-size: 3.5em !important;
            margin-block-start: 5em;

            font-size: 16px;

            border-radius: 8px;

            @media(max-width: 600px) {
                margin-block-start: 1em;
            }
        }
    }
</style>
