<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Content from './Content.vue'

const { t } = useI18n()
</script>

<template>
    <div>
        <h3 class="kidonet-policy-page__widget-header">
            {{ t('dashboard_v2.policy_page.policy_options.header') }}
        </h3>
        <Content />
    </div>
</template>
