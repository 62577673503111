<script lang="ts" setup>
import { computed } from 'vue'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'

const settingsStore = useSettingsStore()

const classList = computed(() => {
    if (settingsStore.simpleUI) {
        return ['main-page-content', 'main-page-content--simple-UI']
    }
    return ['main-page-content']
})
</script>

<template>
    <div :class="classList">
        <router-view name="content" />
    </div>
</template>

<style>
    .main-page-content {
        padding: 1em 2em;
        background-color: rgb(var(--v-theme-mainContentBackground));

        @media(max-width: 400px) {
            padding: 1em;
        }
    }
    .kidzonet-dashboard-widget {
        padding: 16px;
        background-color: var(--widget-background-color);
        border-radius: 16px;
        box-shadow: 0px 4px 8px rgba(36, 36, 36, 0.02);
    }
</style>
