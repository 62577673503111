<script setup lang="ts">
interface CustomPolicyPaginatorInterface {
    page: number
    pagesCount: number
}

defineProps<CustomPolicyPaginatorInterface>()
const emit = defineEmits<{
    (e: 'paginate', value: number): void
}>()

const paginate = (page: number) => {
    emit('paginate', page)
}
</script>

<template>
    <v-pagination
        :model-value="page"
        :length="pagesCount"
        @update:model-value="paginate"
        :total-visible="4"
        active-color="primary"
        density="compact"
        variant="elevated"
        class="kidzonet-paginator"
    />
</template>

<style>
    .kidzonet-paginator {
        .v-btn--icon.v-btn--density-compact {
            inline-size: 100%;
            min-inline-size: calc(var(--v-btn-height) + -8px);
            padding: 0 0.5em;
        }
    }
</style>
