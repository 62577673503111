import { defineStore } from '@kidzonet/pinia'
import { POLICY_DATA_LIST } from '@kidzonet/consts'

import { fetchPolicyList, setPolicyList } from '@kidzonet/ts-api-dashboard-policy'

interface policyItemInterface {
    name: string
    single: boolean
    id?: number
    isActive?: boolean
}

type policyListType = policyItemInterface[]

export const useDasboardPolicyStore = defineStore('dashboard-policy', {
    state: () => {
        const policyList: policyListType = []
        return {
            policyList,
            loading: false,
        }
    },
    getters: {
        activePolicies (): policyListType {
            let result = this.policyList.filter(({ isActive }) => isActive)
            if (result.length === 0) {
                // no active policies return allow all
                result = this.policyList.filter(({ name }) => name === 'allow_all')
            }
            return result
        },
        activePolicy (): policyItemInterface {
            return this.activePolicies.filter(({ single }) => single)[0]
        },
        singlePolicies: ({ policyList }) => policyList.filter(({ single }) => single),
        policySwitchers: ({ policyList }) => policyList.filter(({ single }) => !single),
        policy: ({ policyList }) => (policyId: number) => policyList.find(({ id }) => policyId === id),
        policyByName: ({ policyList }) => (policyName: string) => policyList.find(({ name }) => name === policyName),
        switchersIsDisabled () {
            return this.activePolicy?.name === 'block_all'
        },
    },
    actions: {
        async fetchUserPolicies () {
            this.loading = true
            const policyList = await fetchPolicyList()
            if (policyList) {
                const result: policyListType = POLICY_DATA_LIST.map((item) => {
                    const itemFromAPI = policyList.find(({ name }) => name === item.name)
                    return { ...item, ...itemFromAPI }
                })
                this.policyList = result
            }
            this.loading = false
        },
        async activatePolicy (id?: number) {
            if (this.loading) {
                return
            }
            if (!id) {
                return
            }
            this.loading = true
            let activatedPolicyList: number[] = []
            const checkedPolicy = this.policy(id)
            let requestPolicyListResult = false
            if (id !== undefined && checkedPolicy) {
                if (checkedPolicy.name === 'block_all') {
                    activatedPolicyList = [id]
                } else if (checkedPolicy.single) {
                    activatedPolicyList = [id, ...this.activePolicies.filter(
                        policy => !policy.single && policy.id !== undefined,
                    ).map(item => item.id || 0)]
                } else {
                    activatedPolicyList = this.activePolicies.filter(
                        policy => policy.id !== undefined,
                    ).map(item => item.id || 0)
                    if (checkedPolicy.isActive) {
                        activatedPolicyList = activatedPolicyList.filter(item => item !== id)
                    } else {
                        activatedPolicyList.push(id)
                    }
                }
                if (activatedPolicyList.length > 0) {
                    // prevent single selected policy deactivate
                    requestPolicyListResult = await setPolicyList(
                        activatedPolicyList,
                    )
                }
            }

            if (requestPolicyListResult) {
                this.policyList = this.policyList.map((item) => {
                    item.isActive = activatedPolicyList.includes(item.id || 0)
                    return item
                })
            }

            this.loading = false
        },
        async activateOneClickPolicy (id?: number) {
            if (this.loading) {
                return
            }
            if (!id) {
                return
            }
            this.loading = true
            const requestPolicyListResult = await setPolicyList([id])

            if (requestPolicyListResult) {
                this.policyList = this.policyList.map((item) => {
                    item.isActive = item.id === id
                    return item
                })
            }

            this.loading = false
        },
    },
})
