<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

type shortDayName = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'

interface IntervalItemProps {
    weekdays: shortDayName[]
    startTime: string | number
    stopTime: string | number
}

const props = defineProps<IntervalItemProps>()

const { t } = useI18n()

const removeSecondsFromTime = (value = '') => value.substr(0, 5)

const daysLabel = props.weekdays.map(item => t(`common.days_of_week.short.${item}`)).join(' ')
</script>

<template>
    <v-chip
        color="primary"
        class="schedule-intervals-item-chip"
    >
          {{ daysLabel }}: {{ removeSecondsFromTime(String(startTime)) }} - {{ removeSecondsFromTime(String(stopTime)) }}
    </v-chip>
</template>

<style>
.schedule-intervals-item-chip {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 0.5em 0.5em 0;

    text-transform: capitalize;
}
</style>
