<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useRestorePasswordStore } from '@kidzonet/dashboard-restore-password-store'

const router = useRouter()


const store = useRestorePasswordStore()

if (!store.token) {
    router.push({ name: 'home' })
}

const { t } = useI18n()
const password = ref('')

const passwordWasChanged = ref(false)

const changePassword = async () => {
    const result = await store.changePassword(password.value)
    if (!result) {
        return
    }
    passwordWasChanged.value = true
    if (store.mobile) {
        window.location.replace('kidzonet://?pasword-was-changed=true')
    }
}

const hideErrorMessage = () => {
    store.resetPasswordError = false
}

const buttonIsDisabled = computed(() => {
    return !password.value
})
</script>

<template>
    <div class="login-restore-password-form">
        <h1 class="login-restore-password-header font-bold">
            {{ t('login.restore_password.new_password_form_header') }}
        </h1>
        <div v-if="passwordWasChanged">
            <v-alert
                :text="t('login.restore_password.password_was_changed')"
                type="success"
            />
            <v-btn
                :to="{ name: 'login-root' }"
                color="primary"
                class="login-new-password-form-back-to-login-button"
            >
                {{ t('login.header') }}
            </v-btn>
        </div>

        <div
            class="login-restore-password-form__error"
            v-else-if="store.changePasswordError"
        >
            <v-alert
                :text="t('login.restore_password.password_change_link_is_outdated')"
                type="warning"
            />
            <v-btn
                :to="{ name: 'restore-password' }"
                color="primary"
                class="login-new-password-form-back-to-login-button"
            >
                {{ t('login.restore_password.button') }}
            </v-btn>
        </div>
        <div v-else>
            <v-text-field
                id="username_input"
                :label="t('login.restore_password.password')"
                v-model.trim="password"
                autofocus
                autocapitalize="none"
                variant="outlined"
                @keyup.enter="changePassword"
                @update:model-value="hideErrorMessage"
                color="primary"
            />
            <v-btn
                id="sign_in_button"
                class="login-restore-password-form__button"
                @click="changePassword"
                :disabled="buttonIsDisabled"
                color="primary"
            >
                {{ t('common.buttons.save') }}
            </v-btn>
        </div>
    </div>
</template>

<style>
    .login-new-password-form-back-to-login-button {
        inline-size: 100%;
        margin-block-start: 3em;
    }
</style>
