<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@kidzonet/pinia/UserStore'

const { t } = useI18n()
const store = useAuthStore()
</script>

<template>
    <v-select
        variant="plain"
        prepend-inner-icon="MainMenuUser"
        class="account-menu"
        :menu-props="{
           closeOnContentClick: true,
        }"
    >
        <template #no-data />
        <template #append-item>
            <v-list-item>
                <v-list-item-title
                    class="account-menu__username"
                >
                    {{ store.fullname || store.user.username }}
                </v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item
                :to="{ name: 'my-information' }"
            >
                <template #prepend>
                    <v-icon
                        icon="MainMenuUser"
                        size="small"
                    />
                </template>
                <v-list-item-title
                    class="account-menu__list-item-label"
                >
                    {{ t('dashboard_v2.user_menu.my_information') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="{ name: 'sessions' }"
            >
                <template #prepend>
                    <v-icon
                        size="small"
                        icon="MainMenuActiveSessions"
                    />
                </template>
                <v-list-item-title
                    class="account-menu__list-item-label"
                >
                    {{ t('dashboard_v2.user_menu.active_sessions') }}
                </v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item
                href="/support"
                target="_blank"
            >
                <template #prepend>
                    <v-icon
                        size="small"
                        icon="MainMenuHelp"
                    />
                </template>
                <v-list-item-title
                    class="account-menu__list-item-label"
                >
                    {{ t('dashboard_v2.user_menu.need_help') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="{ name: 'privacy' }"
                target="_blank"
            >
                <template #prepend>
                    <v-icon
                        size="small"
                        icon="MainMenuPrivacy"
                    />
                </template>
                <v-list-item-title
                    class="account-menu__list-item-label"
                >
                    {{ t('dashboard_v2.user_menu.privacy_policy') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item
                :to="{ name: 'tos' }"
                target="_blank"
            >
                <template #prepend>
                    <v-icon
                        size="small"
                        icon="MainMenuTOS"
                    />
                </template>
                <v-list-item-title
                    class="account-menu__list-item-label"
                >
                    {{ t('dashboard_v2.user_menu.terms_and_conditions') }}
                </v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item
                id="logout_button"
                @click="() => store.logout()"
            >
                <template #prepend>
                    <v-icon
                        size="small"
                        icon="MainMenuLogout"
                    />
                </template>
                <v-list-item-title
                    class="account-menu__list-item-label"
                >
                    {{ t('dashboard_v2.user_menu.logout') }}
                </v-list-item-title>
            </v-list-item>
        </template>
    </v-select>
</template>

<style>
    .account-menu {
        display: flex;

        .v-field__field, .v-field__overlay {
            display: none;
        }

        .isax {
            font-size: 20px;
        }

        &__list-item-label {
            font-size: 14px;
        }

        &__username {
            font-weight: 600;
        }
    }

    .v-overlay__content.v-select__content {
        max-block-size: 600px !important;
    }

    .v-list-item__prepend {
        inline-size: 2em;
    }
</style>
