<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
    <div class="page-not-found">
        <h1>404</h1>
        <h2>{{ t('errors.code.404') }}</h2>
    </div>
</template>

<style>
    .page-not-found {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        min-block-size: 70%;
    }
</style>
