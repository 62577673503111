import type { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '@kidzonet/pinia/UserStore'

export const redirectToSavedURLOrGoToTheRequestedURL = (next: NavigationGuardNext) => {
    const nextUrl = localStorage.getItem('kidzonet-next-url-item')
    if (nextUrl) {
        localStorage.removeItem('kidzonet-next-url-item')
        window.location.href = nextUrl
    } else {
        next()
    }
}

export const saveRequestedUrlToLocalStorageAndRedirectToRootURL = (to: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (to.meta.requiresAuth) {
        localStorage.setItem('kidzonet-next-url-item', window.location.href)
        next({
            path: '/',
        })
    } else {
        next()
    }
}

export const authGuardBeforeEachHook: NavigationGuard = async (to, from, next) => {
    const store = useAuthStore()
    if (store.isAuthenticated) {
        redirectToSavedURLOrGoToTheRequestedURL(next)
    } else {
        saveRequestedUrlToLocalStorageAndRedirectToRootURL(to, next)
    }
}
