<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'
import { useSupportStore } from '@kidzonet/dashboard-support-store'

const store = useSupportStore()

useLoadLocaleMessages('support')
const { t, locale } = useI18n({ useScope: 'global' })

// @ts-expect-error types error
const phoneNumber = computed(() => store.phone(locale.value))
// @ts-expect-error types error
const whatsappNumber = computed(() => store.whatsapp(locale.value))
// @ts-expect-error types error
const emailAddress = computed(() => store.email(locale.value))
</script>

<template>
    <div class="support-page-content">
        <h1>{{ t('support.header') }}</h1>
        <section class="support-page-content__content">
            <p>{{ t('support.content') }}</p>
        </section>
        <section class="support-page-content__cards">
            <a
                v-if="phoneNumber"
                class="support-page-content__link"
                :href="`tel:${phoneNumber}`"
            >
                <div>
                    <div class="support-page-content__icon">
                        <span class="support-page-content__icon-phone">📞</span>
                    </div>
                    <div class="support-page-content__item elevation-1">
                        <h2 class="support-page-content__item-header">
                            {{ t('support.call') }}
                        </h2>
                        <span>{{ phoneNumber }}</span>
                    </div>
                </div>
            </a>
            <div v-if="whatsappNumber">
                <div class="support-page-content__icon">
                    <div class="support-page-content__icon-whatsapp" />
                </div>
                <div class="support-page-content__item elevation-1">
                    <h2 class="support-page-content__item-header">
                        {{ t('support.whatsapp') }}
                    </h2>
                    <span>{{ whatsappNumber }}</span>
                </div>
            </div>
            <a
                v-if="emailAddress"
                class="support-page-content__link"
                href="mailto:rcc@kemskw.net"
            >
                <div class="support-page-content__icon">
                    <span class="support-page-content__icon-email">✉️</span>
                </div>
                <div class="support-page-content__item elevation-1">
                    <h2 class="support-page-content__item-header">
                        {{ t('support.email') }}
                    </h2>
                    <span>{{ emailAddress }}</span>
                </div>
            </a>
        </section>
    </div>
</template>

<style>
    .support-page-content {
        margin-block-start: 2em;
        text-align: center;

        &__content {
            margin-block-start: 1em;
            margin-block-end: 10em;
        }

        &__cards {
            display: flex;
            flex-wrap: wrap;
            gap: 2em;
            justify-content: center;
        }

        &__link {
            color: inherit !important;
            text-decoration: none;
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;

            inline-size: 300px;
            padding: 1em;
            padding-block-start: 3em;
        }

        &__item-header {
            padding-block-end: 1em;
            text-transform: capitalize;
        }

        &__icon {
            position: relative;
            inset-block-start: 30px;

            inline-size: 2em;
            block-size: 2em;
            margin: auto;
            padding: 0.5em;

            font-size: 32px;

            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 2px 10px -1px rgb(0 0 0 / 20%);
        }

        &__icon-whatsapp {
            inline-size: 32px;
            block-size: 32px;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAARLSURBVFiFtZddbJNVGMd/z+naSkeXkMlGp/uQ6HCgQgI4dVzN4VhHKIIUY0jUCy80KBcGpzFC0BgCJibceOENIXIjY0CHbAgJEgMmBgkfQ0gUYQPn2CcKW23X9n28cCvr2pVudP+rnuc85/3/+vTtOecRVSUTeVu9zpyws9pS8RmoUFEP4BmZ7hKVLguuGNFA1Bk+0VLXEs7kuXI/AF+zr1Ats1VhA+DOiBbuCuwVY20LrAp0TwnA3+h3BO2RBoEPgJkZGo/XoMJOV8S+Y9+6fcMZA/iafYUxlSZRqZqicYJU9LRNdG2qaiQB1AdWP21UjgDF2TAfo5uWaP0R36G2CQF8zb5CyzJnpsE8DmGMtXRsJczoB3+j3xFTaZpGc4DimEqTv9HvSAII2iMNqX5zT+4cXpv3KpsWvYsn1zN+etISlaqgPdJwb6w6WvqrjHvbS90lbK/6nIdsTgB+vnWGHWe/eGAIYNAY6/HAqkC3AVDLbB1vDvDm/Nfj5gBLCxfz8Iz8bADMHPHEeFu9zpFNJkE2sTFvVnlCzIhheUlNNgBQ2OBt9TpNTthZTYodriyvFOeYbz+qmuLqrAAA7pyws9pYKr5Us/2h/pSrbGJDkKwQWCo+Y6Ai1eTf4X/ouHsjIRa1onx5bhdKZgfY/WSgwoycail1ofdiwvhk549c7GubIHvyUlGP4d6RmqTWjqMMx+6dIZWFSyl0FWQNAPAYIDTRbHewh32/74+P3Q43DYs3p3w5pyojKpfTJRz8I8AvPWfj47K8UjYufCdb/l22J9ZXPCfCknRZZ3vO8bynErfj/39ribuYAlcBbf2XiFrRhFxv2Qrqy+oYCN2mPzSQ1l1UfrU9ub5iLkJdusSIFaGt7xJVRS/Ey/9YXhnLiqq4fuc6vf/2AbCkYDHvLdpIWV4pNSUvsiB/Pv2hAbqDPSmfq8hRqT/kqxGV42lRR/RIbhFbKj9m9ozZCfHzvRe4FexmWVEVM+25Sev2XPmGwLXDyRUArxm6PeuUQFcmAJ1Df/HRT5/QfqcjIb5o9kJWlL6U0hyYaNe4G3WGT5gf3tgdsmB7JgAAA6EBNp/6kN2X9xCMBjNa036nPSkmsLelriVsACxn+Gvgz0whYhrj8PUjbDy5ie87jjMYGZowt+nqwVSb16AYaxuMuZKtPPTy2wJfZQoxVjaxsSB/PpVznuWZ/KcwYqNzqJOW9qOc772QlK+w5bvVBz8DyBkNRiP2b+32yJQAYhrjYl9bRtu0ip52DTt2jI7jVzK7Y3j5VMwnqZs20bVje4R4BVBZN93mlmj94XG9gagqtcdqcx1BVy8wYzqc0zUmBsAZdNWPM78B7FLRT4HUN5PMNKiwxTXsqJ6oR8wBUJV1iP6G6AGJ2Q40r2k6M5pQe6x2pz3oekvgfeDRDI0n15yuPPDK3O/W7L+WLtHf6HeE7BEvKgsUyjFWOSrlgIMHaM//A+PDshJTh69fAAAAAElFTkSuQmCC');
            background-repeat: no-repeat;
        }

        &__icon-phone {
            position: relative;
            inset-block-start: -5px;
        }

        &__icon-email {
            position: relative;
            inset-block-start: -15px;
            inset-inline-start: -5px;
            font-size: 42px;
        }
    }

    @media (max-width: 1030px) {
        .support-page-content__item {
            inline-size: 250px;
        }
    }

    @media (max-width: 880px) {
        .support-page-content__item {
            inline-size: 200px;
        }

        .support-page-content__content {
            margin-block-end: 5em;
        }
    }

    @media (max-width: 500px) {
        .support-page-content__content {
            margin-block-end: 1em;
        }
    }
</style>
