<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const show = ref(!localStorage.getItem('licenseIsAgreed'))
const agree = () => {
    localStorage.setItem('licenseIsAgreed', '1')
    show.value = false
}
</script>

<template>
    <v-footer
        v-if="show"
        fixed
        class="tos-alert"
        :height="64"
    >
        <v-alert
            closable
            @click:close="agree"
            color="secondary"
            class="tos-alert__alert"
        >
            <i18n-t
                keypath="dashboard_v2.license_agreement.text"
                tag="span"
                class="tos-alert__text"
            >
                <router-link
                    to="/tos"
                    target="_blank"
                    class="tos-alert__link"
                >
                    {{ t('dashboard_v2.license_agreement.tos_link') }}
                </router-link>
            </i18n-t>
        </v-alert>
    </v-footer>
</template>

<style>
    .tos-alert {
        position: sticky;
        inset-block-end: 0;
        flex-grow: 0;
        padding: 0;

        &__text {
            display: flex;
            justify-content: center;
            color: var(--tos-alert-text-color);
        }

        &__link {
            display: inline-block;
            margin: 0 0.5em;
            color: var(--tos-alert-text-color);
        }
    }
</style>
