import {
    CreateInterfacesResponseInterface,
    DeleteInterfacesResponseInterface,
    GetInterfacesResponseInterface,
} from './types'

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var DashboardApiClient: any
}

export const getInterfacesList = async () => {
    const { data, error }: GetInterfacesResponseInterface = await globalThis.DashboardApiClient.GET(
        '/v1/public/dashboard/interfaces',
    )

    if (error) {
        throw error
    }
    const { items = [] } = data
    return items.map(net => ({ id: net.id, address: net.interface }))
}

export const createInterface = async (address: string) => {
    const { data, error }: CreateInterfacesResponseInterface = await globalThis.DashboardApiClient.POST(
        '/v1/public/dashboard/interfaces', {
            body: { interface: address },
        },
    )
    if (data) {
        return data.id
    }
    throw error
}

export const deleteInterface = async (id: number) => {
    const { data, error }: DeleteInterfacesResponseInterface = await globalThis.DashboardApiClient.DELETE(
        `/v1/public/dashboard/interfaces/${id}`,
    )

    if (data) {
        return true
    }
    if (error && 'status' in error && error.status === 404) {
        return true
    }
    throw error
}
