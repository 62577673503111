<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import NoData from '@kidzonet/no-data-image'
import TableWrapper from '@kidzonet/dashboard-table-wrapper'
import { useNetsStore } from '@kidzonet/dashboard-nets-store'

const store = useNetsStore()
const { t } = useI18n()

const headers = computed(() => [{
    title: t('dashboard_nets.table.address'),
    sortable: false,
    key: 'address',
}, {
    key: 'action',
    title: t('dashboard_nets.table.actions'),
    sortable: false,
}])
</script>

<template>
    <TableWrapper>
        <div class="nets-table">
            <v-data-table-server
                v-if="store.userNets.length > 0 || store.loading"
                :headers="headers"
                :items="store.userNets"
                :items-length="store.userNets.length"
                :loading="store.loading"
                :items-per-page="10"
                item-value="name"
                :show-select="false"
                :page="1"
            >
                <template #item.address="{ item }">
                    <div class="nets-table__address-column">
                        <span>
                            {{ item.address }}
                        </span>
                        <div
                            v-if="item.address === store.myIP"
                            class="nets-table-current-ip"
                        >
                            <div class="nets-table-current-ip__icon" />
                            {{ t('dashboard_nets.my_ip') }}
                        </div>
                    </div>
                </template>
                <template #item.action="{ item }">
                    <v-btn
                        variant="text"
                        icon
                        color="subHeader"
                        @click="store.removeNet(item)"
                    >
                        <v-icon
                            icon="Trash"
                        />
                    </v-btn>
                </template>
            </v-data-table-server>
            <NoData v-else />
        </div>
    </TableWrapper>
</template>

<style>
    .nets-table {
        font-size: 14px;

        .v-data-table__tbody {
            font-family: Poppins-Medium,Helvetica,Arial,sans-serif!important;
        }

        .v-data-table-footer {
            display: none;
        }
    }

    .nets-table__address-column {
        display: flex;
        align-items: center;
    }

    .nets-table-current-ip {
        display: flex;
        align-items: center;
        color: rgb(var(--v-theme-subHeader));

        &__icon {
            inline-size: 6px;
            block-size: 6px;
            margin: 0 0.5em;

            background-color: rgb(var(--v-theme-subHeader));
            border-radius: 100%;
        }
    }

    .nets-table-header {
        font-size: 16px;
        font-weight: 600;
    }

    .nets-table-footer {
        display: flex;
        align-items: center;
        &__total {
            padding-inline-start: 1em;
            font-size: 16px;
            font-weight: 700;
        }
    }
</style>
