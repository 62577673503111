<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import LanguageSelector from '@kidzonet/dashboard-language-selector'
import AccountMenu from '@kidzonet/dashboard-account-menu'
import OfflineMessage from '@kidzonet/offline-message'
import MindControllWarning from '@kidzonet/dashboard-mindcontrol-message'
import { useAuthStore } from '@kidzonet/pinia/UserStore'
import HeaderLink from './HeaderLink.vue'

const { t } = useI18n()
const store = useAuthStore()
</script>

<template>
    <div class="main-page-header">
        <OfflineMessage />
        <MindControllWarning />
        <div class="main-page-header__content">
            <router-view name="header" />
            <div class="main-page-header__controls">
                <LanguageSelector />
                <div class="main-page-header__divider" />
                <AccountMenu />
                <slot name="append-link">
                    <HeaderLink
                        v-if="store.isAdmin"
                        :title="t('dashboard_v2.user_menu.isp_admin')"
                        link="/admin/"
                    />
                </slot>
            </div>
        </div>
    </div>
</template>

<style>
    .main-page-header {
        padding: 1em 2em;

        &__content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }

        &__header {
            font-family: Poppins-SemiBold,Helvetica,Arial,sans-serif!important;
            font-size: 24px;
            text-transform: capitalize;
        }

        &__divider {
            block-size: 24px;
            margin: 0 1em;
            border-inline-start: 1px solid rgb(var(--v-theme-subHeader));
        }

        &__controls {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
        }

        @media(max-width: 400px) {
            padding: 1em;
        }
    }
</style>
