<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@kidzonet/pinia/UserStore'
import PasswordField from '@kidzonet/password-field'

import BottomContent from './Bottom.vue'

const router = useRouter()
const { t } = useI18n()
const showErrorMessage = ref(false)
const login = ref('')
const password = ref('')
const passwordRef = ref()

const store = useAuthStore()

const pressEnterOnLoginField = () => {
    passwordRef.value.focus()
}

const authUser = async () => {
    const result = await store.authenticate(login.value, password.value)
    if (result === true) {
        router.push({ name: 'home' })
    } else {
        showErrorMessage.value = true
    }
}

const hideErrorMessage = () => {
    showErrorMessage.value = false
}

const buttonIsDisabled = computed(() => {
    return !login.value || !password.value
})
</script>

<template>
    <div class="login-form">
        <v-text-field
            id="username_input"
            :label="t('login.username')"
            v-model.trim="login"
            autofocus
            autocapitalize="none"
            variant="outlined"
            @keyup.enter="pressEnterOnLoginField"
            @update:model-value="hideErrorMessage"
            color="primary"
        />
        <PasswordField
            ref="passwordRef"
            v-model.trim="password"
            @keyup.enter="authUser"
            @update:model-value="hideErrorMessage"
        />
        <div
            class="login-form__error"
        >
            <span v-if="showErrorMessage">
                {{ t('login.invalid_login_error') }}
            </span>
        </div>
        <v-spacer />
        <v-btn
            id="sign_in_button"
            class="login-form__button"
            @click="authUser"
            :disabled="buttonIsDisabled"
            color="primary"
        >
            {{ t('login.login_button') }}
        </v-btn>
        <v-spacer />
        <BottomContent />
    </div>
</template>

<style>
    .login-form  {
        display: flex;
        flex-direction: column;
        block-size: 100%;

        &__button {
            inline-size: 100%;
            block-size: 3.5em !important;
            font-size: 16px;
            border-radius: 8px;
        }

        &__error {
            block-size: 1em;
            color: rgb(var(--v-theme-error));
            text-align: center;
        }

        .v-input {
            flex: none;
        }
    }
</style>
