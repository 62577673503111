<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'

import Content from './Content.vue'

const { t } = useI18n()

const policyStore = useDasboardPolicyStore()
</script>

<template>
    <div class="protection-settings-panel">
        <div
            v-if="policyStore.switchersIsDisabled"
            class="protection-settings-panel__overlay"
        />
        <div class="kidzonet-dashboard-widget protection-settings-panel__inner">
            <h3 class="kidonet-policy-page__widget-header">
                {{ t('dashboard_v2.policy_page.protection_settings.header') }}
            </h3>
            <Content />
        </div>
    </div>
</template>

<style>
    .protection-settings-panel {
        display: grid;
        grid-template-areas: "overlaydemo";

        &__inner {
            grid-area: overlaydemo;
        }

        &__overlay {
            z-index: 1;

            grid-area: overlaydemo;

            inline-size: 100%;
            block-size: 100%;

            background-color: #ccc;
            filter: opacity(0.25);
            border-radius: 16px;
        }
    }
</style>
