<script setup lang="ts">
import { computed } from 'vue'
import TableWrapper from '@kidzonet/dashboard-table-wrapper'
import StatisticTable from '@kidzonet/dashboard-statistic-table'
import { useStatsStore } from '@kidzonet/dashboard-stats-store'
import Paginator from '@kidzonet/paginator'

import Toolbar from './Toolbar/Toolbar.vue'
import TableFooter from './Footer.vue'

const store = useStatsStore()

const showWidgets = computed(() => store.dnsLog.length > 0)
</script>

<template>
    <div>
        <TableWrapper
            :show-footer="showWidgets"
            class="reports-table-page"
        >
            <template #header>
                <span class="reports-table-page-header">
                    <Toolbar />
                </span>
            </template>
            <StatisticTable class="reports-table-page__table" />
            <template #footer>
                <TableFooter />
            </template>
        </TableWrapper>
        <Paginator
            v-if="showWidgets"
            :page="store.page"
            :pages-count="store.pagesCount"
            @paginate="(page) => store.paginate(page)"
            class="reports-table-page__paginator"
        />
    </div>
</template>

<style>
    .reports-table-page {
        min-block-size: calc(min(70vh, 700px));
        font-size: 14px;

        .v-data-table__tbody {
            font-family: Poppins-Medium,Helvetica,Arial,sans-serif!important;
        }

        .v-data-table-footer {
            display: none;
        }

        &__paginator {
            margin-block-start: 1em;
        }
    }
</style>
