<script lang="ts" setup>
import { useAuthStore } from '@kidzonet/pinia/UserStore'
import { useReloadPageOnBroadcastMessage } from '@kidzonet/vue3-broadcast'

import DashboardContent from './DashboardContent.vue'

const store = useAuthStore()
await store.fetchUserInfo()

useReloadPageOnBroadcastMessage()
</script>

<template>
    <div v-if="store.isAuthenticated">
        <DashboardContent />
    </div>
</template>

<style>
    .v-main {
        block-size: 100vh;
        background-color: rgb(var(--v-theme-mainContentBackground));
    }
</style>
