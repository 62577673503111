<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
    <i18n-t
        keypath="login.register.already_have_account"
        tag="div"
        class="register-form-login"
    >
        <template #login>
            <router-link
                :to="{ name: 'login-root' }"
                class="register-form-login__link"
            >
                {{ t('login.register.login') }}
            </router-link>
        </template>
    </i18n-t>
</template>

<style>
    .register-form-login {
        margin-block-end: 3em;
        text-align: center;

        &__link {
            text-decoration: none;
        }
    }
</style>
