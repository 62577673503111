<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'

import ProtectionOnIcon from '@kidzonet/dashboard-policy-icons/src/ProtectionOn.vue'
import BlockAllIcon from '@kidzonet/dashboard-policy-icons/src/BlockAll.vue'

import PolicyItem from '@kidzonet/dashboard-policy-widget'

import RadioIcon from '@kidzonet/dashboard-radio-icon'

const { t } = useI18n()

useLoadLocaleMessages('policy')

const store = useDasboardPolicyStore()
store.fetchUserPolicies()

const settingsStore = useSettingsStore()

const protectionOnPolicy = ref({ id: 0, name: '', isActive: false })
const protectionOffPolicy = ref({ id: 0, name: '', isActive: false })

const activateProtectionOn = () => {
    if (protectionOnPolicy.value.isActive) {
        return
    }
    if (protectionOnPolicy.value.id === undefined) {
        return
    }
    Promise.all([
        store.activateOneClickPolicy(protectionOnPolicy.value.id),
        settingsStore.setSafeSearch(true),
    ])
}

const activateProtectionOff = () => {
    if (protectionOffPolicy.value.isActive) {
        return
    }
    if (protectionOffPolicy.value.id === undefined) {
        return
    }
    Promise.all([
        store.activateOneClickPolicy(protectionOffPolicy.value.id),
        settingsStore.setSettings({ safeSearch: false, safeYoutube: false }),
    ])
}

store.$subscribe(() => {
    if (store.activePolicy === undefined) {
        return
    }

    // eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
    // @ts-ignore
    protectionOnPolicy.value = store.policyByName('holiday_time')

    // eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
    // @ts-ignore
    protectionOffPolicy.value = store.policyByName('allow_all')
})
</script>

<template>
    <div class="simple-interface-page">
        <div>
            <div
                class="kidzonet-dashboard-widget simple-interface-content"
            >
                <PolicyItem
                    :name="protectionOnPolicy.name"
                    :header="t('policy.protection_on.name')"
                    :subheader="t('policy.protection_on.description')"
                    :id="protectionOnPolicy.id"
                    :is-active="protectionOnPolicy.isActive"
                    @click="activateProtectionOn"
                    class="simple-interface-content__item"
                >
                    <template #icon>
                        <ProtectionOnIcon />
                    </template>
                    <RadioIcon :is-active="protectionOnPolicy.isActive" />
                </PolicyItem>
                <PolicyItem
                    :name="protectionOffPolicy.name"
                    :id="protectionOffPolicy.id"
                    :is-active="protectionOffPolicy.isActive"
                    @click="activateProtectionOff"
                    class="simple-interface-content__item"
                >
                    <template #icon>
                        <BlockAllIcon />
                    </template>
                    <RadioIcon :is-active="protectionOffPolicy.isActive" />
                </PolicyItem>
            </div>
        </div>
    </div>
</template>

<style>
    .simple-interface-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        block-size: 60vh;
    }
    .simple-interface-content {
        min-inline-size: 500px;
        max-inline-size: 500px;

        &__item:first-child {
            margin-block-end: 1em;
        }

        @media(max-width: 800px) {
            min-inline-size: inherit;
        }
    }
</style>
