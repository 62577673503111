<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useScheduleStore } from '@kidzonet/dashboard-schedule-store'
import AddFormDialog from './AddForm/AddForm.vue'
import IntervalItem from './IntervalItem.vue'

type shortDayName = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'

interface Interval {
    id: number
    weekdays: shortDayName[]
    startTime: string
    stopTime: string
}

interface PolicyScheduleInterface {
    id: number
    name: string
    intervals: Interval[]
}

const props = defineProps<PolicyScheduleInterface>()

const store = useScheduleStore()

const addInterval = async (
    { start, end, weekdays, closeDialog }: { start: string, end: string, weekdays: shortDayName[], closeDialog: () => void },
) => {
    await store.addInterval({
        name: props.name,
        id: props.id,
        startTime: start,
        stopTime: end,
        weekdays,
    })
    if (!store.showError && !store.addLoading) {
        closeDialog()
    }
}
const { t } = useI18n()
</script>

<template>
    <div class="policy-item-schedule-inner">
        <ul
            v-if="props.intervals.length > 0"
            class="policy-item-schedule-inner__intervals"
        >
            <IntervalItem
                v-for="interval in props.intervals"
                :key="interval.id"
                v-bind="interval"
                :name="name"
                :policy-id="id"
            />
        </ul>
        <div
            v-else
            class="policy-item-schedule-inner__no-interval-message"
        >
            {{ t('dashboard_v2.policy_page.schedule.no_schedule') }}
        </div>
        <div class="policy-item-schedule-inner__vertical-spacer" />
        <AddFormDialog
            class="policy-item-schedule-inner__button"
            @select-time="addInterval"
        />
    </div>
</template>

<style>
.policy-item-schedule-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    &__header {
        display: flex;
        gap: 1em;
        align-items: center;

        margin-block-end: 1em;

        font-size: 16px;
        text-transform: capitalize;
    }

    &__vertical-spacer {
        flex-grow: 1;
    }

    &__intervals {
        display: flex;
        flex-wrap: wrap;

        margin: 0;
        padding: 0;

        list-style-type: none;
    }

    &__button {
        align-self: flex-end;
        margin-block-start: 1em;
    }

    &__no-interval-message {
        font-size: 14px;
        color: rgb(var(--v-theme-subHeader));
    }
}
</style>
