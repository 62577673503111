<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface CategoryColumnProps {
    categoryList: number[]
}

const props = defineProps<CategoryColumnProps>()
const { t } = useI18n()

const cleanedCatList = computed(() => {
    if (
        props.categoryList
        && props.categoryList.length > 1
        && props.categoryList[0] === 0
    ) {
        return props.categoryList.slice(1)
    }
    return props.categoryList
})

const label = computed(() => {
    if (cleanedCatList.value && cleanedCatList.value.length > 0) {
        return t(`categories.${cleanedCatList.value[0]}`)
    }
    return ''
})

const additionalCategories = computed(() => {
    if (label.value === '') {
        return []
    }
    return cleanedCatList.value.slice(1)
})
</script>

<template>
    <div class="category-column-item">
        <span v-if="label">
            {{ label }}
        </span>
        <v-menu
            v-if="additionalCategories.length"
            open-on-hover
        >
            <template #activator="act">
                <v-chip
                    size="small"
                    v-bind="act.props"
                >
                    +{{ additionalCategories.length }}
                </v-chip>
            </template>
            <ul class="category-column-item__hided-categories-list">
                <li
                    v-for="(item, index) in additionalCategories"
                    :key="index"
                    :value="index"
                    class="category-column-item__hided-categories-list-item"
                >
                    {{ t(`categories.${item}`) }}
                </li>
            </ul>
        </v-menu>
    </div>
</template>

<style>
    .category-column-item {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;
        align-items: center;

        &__hided-categories-list {
            padding: 0 1em;

            list-style-type: none;

            background-color: var(--widget-background-color);
            border: 1px solid #ccc;
            border-radius: 1em;
        }

        &__hided-categories-list-item {
            margin: 1em 0;
        }
    }
</style>
