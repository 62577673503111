<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'

import PolicyWidget from '@kidzonet/dashboard-overview-policy-widget'
import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'
import { getPolicyName } from '@kidzonet/use-policy'

const { t } = useI18n()
const store = useDasboardPolicyStore()
const settingsStore = useSettingsStore()

store.fetchUserPolicies()

useLoadLocaleMessages('policy')

const subheader = computed(() => {
    const result = store.activePolicies.map(
        ({ name }) => getPolicyName(name),
    )
    if (settingsStore.safeYoutube) {
        result.push(t('common.manual_policy.safe_youtube'))
    }
    if (settingsStore.safeSearch) {
        result.push(t('common.manual_policy.safe_search'))
    }
    return result.join(' + ')
})
</script>

<template>
    <router-link
        :to="{ name: 'policy' }"
        class="overview-policy-widget-link-wrapper"
    >
        <PolicyWidget
            :header="t('dashboard_v2.overview.policy_header')"
            :subheader="subheader || '&nbsp;'"
            icon="OverviewCurrentPolicyIcon"
            :icon-color="{
                background: 'linear-gradient(135deg, #E6F5FA 0%, #0099CB 100%)',
                boxShadow: '0px 6px 4px -2px #0099CB40',
            }"
        />
    </router-link>
</template>

<style>
    .overview-policy-widget-link-wrapper {
        color: inherit;
        text-decoration: inherit;
    }
</style>
