<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useScheduleStore } from '@kidzonet/dashboard-schedule-store'

import DaySwitcher from './DaySwitcher.vue'
import TimeSwitcher from './TimeSwitcher.vue'
import Errors from './Errors.vue'

const emit = defineEmits(['selectTime'])

const { t } = useI18n()

const dialog = ref(false)

const start = ref(0)
const end = ref(1)
const weekdays = ref([])

const store = useScheduleStore()

const resetForm = () => {
    dialog.value = false
}

const save = async () => {
    await emit('selectTime', {
        start: `${start.value}:00`,
        end: `${end.value}:00`,
        weekdays: weekdays.value,
        closeDialog: resetForm,
    })
    if (!store.showError && !store.addLoading) {
        resetForm()
    }
}

const cancel = () => {
    resetForm()
    store.clearErrors()
}

watch(start, () => {
    store.clearErrors()
})

watch(end, () => {
    store.clearErrors()
})

watch(weekdays, () => {
    store.clearErrors()
})

watch(dialog, (newValue) => {
    if (newValue) {
        return
    }
    start.value = 0
    end.value = 1
    weekdays.value = []
})

const isValid = computed(() => {
    if (weekdays.value.length === 0) {
        return false
    }

    if (start.value === end.value) {
        return false
    }

    if (start.value > end.value && end.value > 0) {
        return false
    }

    return true
})
</script>

<template>
    <v-dialog
        v-model="dialog"
        width="auto"
    >
        <template #activator="{ props }">
            <v-btn
                variant="text"
                color="primary"
                v-bind="props"
            >
                {{ t('common.buttons.add') }}
            </v-btn>
        </template>
        <div class="schedule-add-form kidzonet-dashboard-widget">
            <h2 class="schedule-add-form__header">
                {{ t('dashboard_v2.policy_page.schedule.add_form.header') }}
            </h2>
            <TimeSwitcher
                v-model:start="start"
                v-model:end="end"
            />
            <DaySwitcher v-model="weekdays" />
            <Errors />
            <div class="schedule-add-form__buttons">
                <v-btn
                    color="primary"
                    variant="text"
                    @click="cancel"
                >
                    {{ t('common.buttons.cancel') }}
                </v-btn>
                <v-btn
                    :disabled="store.showError || !isValid"
                    color="primary"
                    @click="save"
                >
                    {{ t('common.buttons.add') }}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<style>
    .schedule-add-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        inline-size: 33em;

        &__header {
            align-self: flex-start;
        }

        &__buttons {
            display: flex;
            justify-content: space-between;
            inline-size: 100%;
            margin-block-start: 2em;
        }
    }
</style>
