<script setup lang="ts">
import { useScheduleStore } from '@kidzonet/dashboard-schedule-store'
import { useI18n } from 'vue-i18n'

import OverlappedIntervals from './OverlappedIntervals.vue'

const { t } = useI18n()

const store = useScheduleStore()
</script>

<template>
    <div
        v-if="store.showError"
        class="schedule-add-form-errors"
    >
        <div class="schedule-add-form-errors__header">
            {{ t('dashboard_v2.policy_page.schedule.add_form.errors_header') }}
        </div>
        <div
            v-if="store.addValidationError"
            class="schedule-add-form-errors__validation-error-header"
        >
            {{ t('dashboard_v2.policy_page.schedule.add_form.invalid_interval_error') }}
        </div>
        <OverlappedIntervals />
    </div>
</template>

<style>
    .schedule-add-form-errors {
        inline-size: 100%;
        padding: 1em 2em 0;

        &__header {
            margin-block-end: 1em;
            font-size: 14px;
            color: rgb(var(--v-theme-error));
        }

        &__validation-error-header {
            font-size: 14px;
            color: rgb(var(--v-theme-error));
        }
    }
</style>
