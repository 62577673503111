<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useEnvVar } from '@kidzonet/vue3-env-var'
import { useRestorePasswordStore } from '@kidzonet/dashboard-restore-password-store'

import RestorePasswordForm from './RestorePasswordForm.vue'

const { t } = useI18n()
const showContent = computed(() => useEnvVar('VITE_SHOW_PASSWORD_RESTORE'))

const store = useRestorePasswordStore()

const getParams = new URLSearchParams(document.location.search)

const token = getParams.get('token') || ''
const isMobile = getParams.get('mobile')
store.token = token
store.mobile = isMobile ? isMobile === 'True' : false
</script>

<template>
    <div
        v-if="showContent"
        class="login-restore-password-page"
    >
        <div>
            <router-view />
            <router-link
                v-if="!store.mobile"
                :to="{ name: 'login-root' }"
                class="login-restore-password-form__back-link"
            >
                {{ t('login.header') }}
            </router-link>
        </div>
    </div>
</template>

<style>
    .login-restore-password-page {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .login-restore-password-header {
        margin: 2em 0 2.5em;
        font-size: 1.5em;
        text-transform: capitalize;

        @media(max-width: 600px) {
            margin: 1em 0;
        }

        @media(max-height: 900px) {
            margin: 1em 0;
        }
    }

    .login-restore-password-form  {
        display: flex;
        flex-direction: column;
        block-size: 100%;

        &__button {
            inline-size: 100%;
            block-size: 3.5em !important;
            margin-block-start: 5em;

            font-size: 16px;

            border-radius: 8px;

            @media(max-width: 600px) {
                margin-block-start: 1em;
            }
        }

        &__error {
            block-size: 1em;
            color: rgb(var(--v-theme-error));
            text-align: center;
        }

        &__back-link {
            display: block;

            inline-size: 100%;
            margin-block-start: 1em;

            color: rgb(var(--v-theme-primary));
            text-align: center;
            text-decoration: underline;
            text-decoration-color: transparent;

            transition: 0.8s;

            &:hover {
                text-decoration-color: rgb(var(--v-theme-primary));
            }
        }

        .v-input {
            flex: none;
        }
    }
</style>
