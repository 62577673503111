<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { usePerCatActivityStore } from '@kidzonet/dashboard-activity-stats-store'
import { useClickOnCurrentMenuItemListener } from '@kidzonet/vue3-event-bus'
import NoData from '@kidzonet/no-data-image'

import ListItem from './Item.vue'

const colorList = ['#5047B7', '#FFA2C0', '#BCE2E0', '#FF9A7B']

const store = usePerCatActivityStore()

store.fetchPerCatActivity()

const activityList = computed(() => {
    return store.activityList.map((item, index) => {
        item.color = colorList[index]
        return item
    })
})

const { t } = useI18n()
useClickOnCurrentMenuItemListener(store.fetchPerCatActivity)
</script>

<template>
    <div class="most-popular-categories">
        <h3 class="overview-header-2 font-medium">
            {{ t('dashboard_v2.overview.most_blocked_categories') }}
        </h3>
        <v-progress-linear
            v-if="store.loading"
            indeterminate
            color="primary"
        />
        <div
            class="most-popular-categories__items"
        >
            <NoData
                v-if="!store.loading && activityList.length === 0"
            />
            <ListItem
                v-for="item in activityList"
                :key="item.catId"
                v-bind="item"
                class="most-popular-categories__item"
            />
        </div>
    </div>
</template>

<style>
    .most-popular-categories {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__items {
            display: flex;

            block-size: 135px;
            margin-block-start: 1.5em;

            border: 1px solid #E4E4E4;
            border-radius: 12px;

            @media(max-width: 600px) {
                flex-wrap: wrap;
                block-size: auto;
            }
        }
        &__item {
            border-inline-end: 1px solid #E4E4E4;

            @media(max-width: 600px) {
                border-inline-end: none;
            }
        }
        &__item:last-child {
            border-inline-end: none;
        }
    }
</style>
