<script setup lang="ts">
import HourSwitcher from './HourSwitcher.vue'

interface TimeSwitcherProps {
    start: number
    end: number
}

const props = defineProps<TimeSwitcherProps>()

const emit = defineEmits(['update:start', 'update:end'])
</script>

<template>
    <div class="schedule-tiem-switcher">
        <HourSwitcher
            :value="props.start"
            @change="value => emit('update:start', value)"
        />
        <div class="schedule-tiem-switcher__hyphen">
            -
        </div>
        <HourSwitcher
            :value="props.end"
            @change="value => emit('update:end', value)"
        />
    </div>
</template>

<style>
    .schedule-tiem-switcher {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        font-size: 4em;
        text-align: center;

        &__hyphen {
            inline-size: 1em;
        }
    }
</style>
