<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import QrcodeVue from 'qrcode.vue'

import { useQrCodeStore } from '@kidzonet/dashboard-qr-code-store'
import getRestApiUrl from '@kidzonet/get-rest-api-url'

import StaleTokenOverlay from './StaleTokenOverlay.vue'

const { t } = useI18n()

const store = useQrCodeStore()

const apiUrl = getRestApiUrl()

const code = computed(() => {
    if (store.token) {
        return `kidzonet://?token=${store.token}&api=${apiUrl}`
    }
    return null
})

const qrCodeWidth = computed(() => {
    if (window?.innerWidth > 500) {
        return 330
    }
    return 200
})
</script>

<template>
    <div class="qr-code-container">
        <div class="qr-code-container__header">
            <div class="mobile-app-list-item">
                2
            </div>
            <div>
                {{ t('dashboard_v2.mobile.scan_qr_code') }}
            </div>
        </div>
        <div class="qr-code-container__wrapper">
            <StaleTokenOverlay
                v-if="store.tokenStale"
            />
            <QrcodeVue
                v-if="code"
                :value="code"
                :size="qrCodeWidth"
                class="qr-code-container__qr-code"
            />
        </div>
    </div>
</template>

<style>
    .qr-code-container {
        &__header {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;
            align-items: center;

            margin: 1em 0;
        }

        &__qr-code {
            margin: 1em;
        }

        &__wrapper {
            display: inline-flex;
            background-color: white;
            border: 6px solid rgb(var(--v-theme-primary));
            border-radius: 16px;
        }
    }
</style>
