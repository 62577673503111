<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useScheduleStore } from '@kidzonet/dashboard-schedule-store'
import Content from './Content.vue'

interface PolicyItemScheduleInterface {
    name: string
    id: number
}

const props = defineProps<PolicyItemScheduleInterface>()

const { t } = useI18n()
const store = useScheduleStore()

// eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
// @ts-ignore
const safari = ref(Boolean(window?.safari))

store.fetchSchedule({ id: props.id, name: props.name })

// eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
// @ts-ignore
const intervals = computed(() => store[props.name])

const onUpdateComponent = () => {
    if (!safari.value) {
        return
    }
    const panel = document.querySelector<HTMLElement>('.policy-item--active')
    if (panel === null) {
        return
    }
    panel.style.borderStyle = 'dotted'
    setTimeout(() => {
        panel.setAttribute('style', '')
    }, 300)
}
</script>

<template>
    <v-expansion-panels
        class="policy-item-schedule"
        selected-class="policy-item-schedule__expansional-panel--active"
        @update:model-value="onUpdateComponent"
    >
        <v-expansion-panel
            class="policy-item-schedule__expansional-panel"
            :elevation="0"
            :disabled="store.loading"
        >
            <v-expansion-panel-title>
                <div
                    class="policy-item-schedule__expansional-panel-title"
                >
                    <v-icon
                        icon="Schedule"
                        color="primary"
                        size="24"
                    />
                    <span>
                        <div class="policy-item-schedule__expansional-panel-title-title">
                            {{ t('dashboard_v2.policy_page.schedule.header') }}
                        </div>
                    </span>
                </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <Content
                    :id="id"
                    :name="name"
                    :intervals="intervals"
                />
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<style>
    .policy-item-schedule {
        &__expansional-panel {
            background-color: unset !important;

            &--active {
                background-color: unset;
            }
        }

        .v-expansion-panel-title {
            padding: 1em 0.5em;
        }

        &__expansional-panel-title {
            display: flex;
            gap: 1em;
            align-items: center;
        }
        &__expansional-panel-title-title {
            line-height: 2em;
            color: rgb(var(--v-theme-primary));
        }
        &__expansional-panel-title-subtitle {
            font-size: 12px;
            color: rgb(var(--v-theme-subHeader));
        }

        .v-expansion-panel-text__wrapper {
            padding-inline: 0;
        }
    }
</style>
