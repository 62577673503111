<script setup lang="ts">
interface PolicyWidgetIconProps {
    icon: string
}

const props = defineProps<PolicyWidgetIconProps>()
</script>

<template>
    <div class="kidzonet-dashboard-widget-icon">
        <v-icon
            :icon="props.icon"
            color="white"
            size="28"
        />
    </div>
</template>

<style>
    .kidzonet-dashboard-widget-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        inline-size: 3.5em;
        min-inline-size: 3.5em;
        block-size: 3.5em;

        border-radius: 12px;

        @media(max-width: 600px) {
            display: none;
        }
    }
</style>
