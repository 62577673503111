<script lang="ts" setup>
import { computed } from 'vue'
import { IResult } from 'ua-parser-js'

// TODO: export types doesnt work now https://github.com/vuejs/core/issues/4294
interface UserAgentIconProps {
    agent: IResult
}
const props = defineProps<UserAgentIconProps>()

const icon = computed(() => {
    const { type, vendor } = props.agent.device
    if (type === 'console') {
        if (vendor === 'Microsoft') {
            return 'fa:fab fa-xbox'
        }
        if (vendor === 'Sony') {
            return 'fa:fab fa-playstation'
        }
    }
    if (props.agent.browser && props.agent.browser.name) {
        const browser = props.agent.browser.name.toLowerCase()
        if (browser.includes('chrome')) {
            return 'fa:fab fa-chrome'
        }
        if (browser.includes('firefox')) {
            return 'fa:fab fa-firefox-browser'
        }
        if (browser.includes('safari')) {
            return 'fa:fab fa-safari'
        }
        if (browser.includes('opera')) {
            return 'fa:fab fa-opera'
        }
        if (browser.includes('edge')) {
            return 'fa:fab fa-edge'
        }
    }
    let osName = props.agent.os && props.agent.os.name
    if (osName) {
        osName = osName.toLowerCase()
        if (osName.includes('android')) {
            return 'fa:fab fa-android'
        }
        if (osName.includes('ios')) {
            return 'fa:fab fa-apple'
        }
    }
    if (vendor === 'Apple') {
        return 'fa:fab fa-apple'
    }
    const { ua } = props.agent
    if (ua && ua.includes('okhttp')) {
        return 'fa:fab fa-android'
    }
    if (ua === 'schedule cronjob') {
        return 'event'
    }
    return 'computer'
})
</script>

<template>
    <div class="user-agent-icon">
        <v-icon
            color="#2B4658"
            size="x-small"
            :icon="icon"
        />
    </div>
</template>

<style>
    .user-agent-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        inline-size: 2.5em;
        block-size: 2.5em;
        margin-inline-end: 1em;

        background-color: #EAEDEE;
        border-radius: 100%;
    }
</style>
