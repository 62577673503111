<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'
import { ExclusionType } from '@kidzonet/ts-api-exclusion-lists/src/types'

import domainValidation from '@kidzonet/vue3-domain-validation'

interface DomainInterface {
    domain: string
    id: number
    type: ExclusionType
}

interface CustomPolicyAddFormInterface {
    label: string
    list: DomainInterface[]
}

const props = defineProps<CustomPolicyAddFormInterface>()
const emit = defineEmits<{
    (e: 'addDomain', value: string): void
}>()

const { t } = useI18n()

const domain = ref('')

const rules = computed(() => ({
    domain: {
        ...domainValidation,
        isUnique: helpers.withMessage(t('common.domains.errors.notUnique'), (value) => {
            return props.list.every(item => item.domain !== value)
        }),
    },
}))

// eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
// @ts-ignore
const $v = useVuelidate(rules, { domain })

const blur = () => $v.value.domain.$touch()

const domainErrors = computed(() => {
    const errors: string[] = []
    if (!$v.value.domain.$dirty || domain.value === '') {
        return errors
    }
    if ($v.value.domain.$invalid) {
        if ($v.value.domain.isUnique.$invalid) {
            errors.push($v.value.domain.isUnique.$message)
        } else {
            errors.push(t('common.domains.errors.invalidDomain'))
        }
    }
    return errors
})

const addButtonIsDisabled = computed(() => $v.value.$invalid)

const add = async () => {
    $v.value.domain.$touch()
    if (addButtonIsDisabled.value) {
        return
    }
    await emit('addDomain', domain.value)
    domain.value = ''
    await nextTick()
    $v.value.domain.$reset()
}

const hideErrorMessage = () => null
</script>

<template>
    <div class="custom-policy-page-add-form">
        <v-text-field
            :label="props.label"
            variant="outlined"
            color="primary"
            clearable
            v-model="domain"
            :error-messages="domainErrors"
            @blur="blur"
            @update:model-value="hideErrorMessage"
            @keyup.enter="add"
            :error="domainErrors.length > 0"
            minlength="3"
            maxlength="300"
            class="custom-policy-page-add-form__input"
        />
        <v-btn
            color="primary"
            class="custom-policy-page-add-form__button"
            :disabled="addButtonIsDisabled"
            @click="add"
        >
            {{ t('dashboard_v2.custom_policy_page.add_form.button') }}
        </v-btn>
    </div>
</template>

<style>
    .custom-policy-page-add-form {
        display: flex;
        gap: 1em;
        margin-block: 1em;
        padding: 14px 14px 8px !important;

        &__input {
            @media(max-width: 700px) {
                inline-size: 200px;
            }
        }

        &__button {
            inline-size: 13em;
            block-size: 4em !important;
            font-weight: 500;
            border-radius: 8px;

            @media(max-width: 700px) {
                inline-size: auto;
            }

            @media(max-width: 400px) {
                inline-size: 100%;
            }
        }

        @media(max-width: 700px) {
            flex-wrap: wrap;
        }
    }
</style>
