<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { LANG_NAMES } from '@kidzonet/consts'
import { AVALIABLE_LANGUAGES } from '@kidzonet/vue-i18n-package'

const { locale } = useI18n({ useScope: 'global' })

const languages = computed(() => AVALIABLE_LANGUAGES.map(value => ({
    value,
    lang: LANG_NAMES[value],
    isActive: locale.value === value,
})))

// eslint-disable-next-line ts/ban-ts-comment,  ts/prefer-ts-expect-error
// @ts-ignore
const safari = ref(Boolean(window?.safari))

const changeLanguage = (lang: string) => {
    localStorage.setItem('locale', lang)
    window.location.reload()
}
</script>

<template>
    <div class="support-page-toolbar-language-selector">
        <span
            v-for="({ value, lang, isActive }) in languages"
            :class="{
                'support-page-toolbar-language-selector__item': true,
                'support-page-toolbar-language-selector__item--active': isActive,
                'support-page-toolbar-language-selector__item--safari': safari,
            }"
            :key="lang"
            v-text="lang"
            @click="changeLanguage(value)"
        />
    </div>
</template>

<style>
    .support-page-toolbar-language-selector {
        display: flex;
        flex-wrap: wrap;
        color: #fff !important;

        &__item {
            cursor: pointer;
            padding-inline-start: 1em;

            &::before {
                content: '|';
                display: inline-block;
                padding-inline-end: 1em;
            }

            &:hover {
                text-decoration: underline;
            }

            &:first-of-type {
                &::before {
                    content: '';
                }
            }

            &--active {
                text-decoration: underline;
            }

            &--safari {
                &::marker {
                    color: var(--v-mainMenu-base);
                }
            }
        }
    }
</style>
