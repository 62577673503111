import { createRouter, createWebHistory } from 'vue-router'
import PrivacyUrl from '@kidzonet/dashboard-privacy-policy/src/route'
import TOSUrl from '@kidzonet/dashboard-tos/src/route'
import NotFoundRoute from '@kidzonet/error-404'
import { authGuardBeforeEachHook } from '@kidzonet/vue3-navigation-guard'

import SupportUrls from './SupportUrls'
import AuthenticatedUrls from './AuthenticatedUrls'
import LoginUrls from './LoginUrls'

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        ...LoginUrls,
        ...AuthenticatedUrls,
        PrivacyUrl,
        TOSUrl,
        ...SupportUrls,
        NotFoundRoute,
    ],
})

router.beforeEach(authGuardBeforeEachHook)

export default router
