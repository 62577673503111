<script setup lang="ts">
import { ref } from 'vue'
import { useStatsStore } from '@kidzonet/dashboard-stats-store'

import CategorySelector from '@kidzonet/category-selector'
import StatusSelector from '@kidzonet/dashboard-statistic-status-selector'
import Search from './Search.vue'

const store = useStatsStore()

const category = ref(store.categoryFilter)
const status = ref(store.statusFilter)

const changeStatus = (value: string) => {
    // @ts-expect-error difficult to types
    store.changeStatus(value)
}

const changeCategory = (value: null | number) => {
    store.changeCategory(value)
}
</script>

<template>
    <div class="reports-table-toolbar">
        <div class="reports-table-toolbar__dropdowns">
            <CategorySelector
                v-model="category"
                @update:model-value="changeCategory"
                class="reports-table-toolbar-dropdowns__selector"
            />
            <StatusSelector
                v-model="status"
                @update:model-value="changeStatus"
                class="reports-table-toolbar-dropdowns__selector"
            />
        </div>
        <div class="reports-table-toolbar__divider" />
        <Search class="reports-table-toolbar__search" />
    </div>
</template>

<style>
    .reports-table-toolbar {
        display: flex;
        flex-wrap: wrap;

        &__dropdowns {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;

            @media(max-width: 650px) {
                display: block;
                gap: 0;
                margin: 0;
            }
        }

        &__divider {
            block-size: 60px;
            margin: 0 1em;
            border-inline-start: 1px solid #E4E4E4;

            @media(max-width: 1300px) {
                display: none;
            }
        }

        &__search {
            inline-size: 40%;

            @media(max-width: 1300px) {
                inline-size: 100%;
            }
        }

        @media(max-width: 900px) {
            flex-direction: column;
        }
    }

    .reports-table-toolbar-dropdowns__selector {
       inline-size: 270px;
       max-inline-size: 270px;

        @media(max-width: 650px) {
            inline-size: 100%;
            max-inline-size: 100%;
        }
    }
</style>
