<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useQrCodeStore } from '@kidzonet/dashboard-qr-code-store'
import MobileAppLinks from './MobileAppLinks.vue'
import QRCode from './QRCode.vue'

const { t } = useI18n()
const store = useQrCodeStore()
store.getToken()
</script>

<template>
    <div class="kidzonet-dashboard-widget mobile-app-page">
        <h2>{{ t('dashboard_v2.mobile.subheader') }}</h2>
        <MobileAppLinks />
        <QRCode class="mobile-app-page__qr-code" />
    </div>
</template>

<style>
    .mobile-app-page {
        &__qr-code {
            margin-block: 4em;

            @media(max-height: 900px) {
                margin: 2em 0 1em;
            }
        }
    }
    .mobile-app-list-item {
        display: flex;
        align-items: center;
        justify-content: center;

        inline-size: 2em;
        block-size: 2em;

        border: 1px solid rgb(var(--v-theme-primary));
        border-radius: 100%;
    }
</style>
