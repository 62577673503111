<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'

import NoData from '@kidzonet/no-data-image'
import TableWrapper from '@kidzonet/dashboard-table-wrapper'
import { ExclusionType } from '@kidzonet/ts-api-exclusion-lists/src/types'

interface DomainInterface {
    domain: string
    id: number
    type: ExclusionType
}

interface CustomPolicyTableInterface {
    loading: boolean
    page: number
    total: number
    list: DomainInterface[]
    header: string
    sortOrder: 'asc' | 'desc'
}

const props = defineProps<CustomPolicyTableInterface>()
const emit = defineEmits<{
    (e: 'removeDomain', domain: DomainInterface): void
    (e: 'changeSortOrder'): void
}>()

const { t } = useI18n()

const { xs } = useDisplay()

const headers = computed(() => [{
    title: t('dashboard_v2.custom_policy_page.table.table_headers.domain'),
    sortable: true,
    key: 'domain',
}, {
    key: 'action',
    title: t('dashboard_v2.custom_policy_page.table.table_headers.actions'),
    sortable: false,
    width: xs.value ? 30 : 180,
}])

const removeDomain = (domain: DomainInterface) => {
    emit('removeDomain', domain)
}
</script>

<template>
    <TableWrapper
        show-footer
    >
        <template #header>
            <span class="custom-policy-page-table-header">
                {{ header }}
            </span>
        </template>
        <div class="custom-policy-page-table">
            <v-data-table-server
                v-if="props.list.length > 0 || props.loading"
                :headers="headers"
                :items="props.list"
                :items-length="props.total"
                :loading="props.loading"
                :items-per-page="10"
                item-value="name"
                :show-select="false"
                :must-sort="!props.loading"
                :page="props.page"
                :sort-by="[{
                    key: 'domain',
                    order: props.sortOrder,
                }]"
                @update:sort-by="() => emit('changeSortOrder')"
            >
                <template #item.action="{ item }">
                    <v-btn
                        variant="text"
                        icon
                        color="subHeader"
                        @click="() => removeDomain(item)"
                    >
                        <v-icon
                            icon="Trash"
                        />
                    </v-btn>
                </template>
            </v-data-table-server>
            <NoData v-else />
        </div>
        <template #footer>
            <div class="custom-policy-page-table-footer">
                <span>
                    {{ t('dashboard_v2.custom_policy_page.table.footer.total') }}
                </span>
                <span
                    class="custom-policy-page-table-footer__total"
                >
                    {{ total }}
                </span>
            </div>
        </template>
    </TableWrapper>
</template>

<style>
    .custom-policy-page-table {
        font-size: 14px;

        .v-data-table__tbody {
            font-family: Poppins-Medium,Helvetica,Arial,sans-serif!important;
        }

        .v-data-table-footer {
            display: none;
        }
    }

    .custom-policy-page-table-header {
        font-size: 16px;
        font-weight: 600;
    }

    .custom-policy-page-table-footer {
        display: flex;
        align-items: center;
        &__total {
            padding-inline-start: 1em;
            font-size: 16px;
            font-weight: 700;
        }
    }
</style>
