<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useEnvVar } from '@kidzonet/vue3-env-var'
import LanguageSelector from '@kidzonet/dashboard-language-selector'

const { t } = useI18n()

const showRegisterLink = useEnvVar('VITE_SHOW_SIGNUP')
const showRestorePasswordLink = useEnvVar('VITE_SHOW_PASSWORD_RESTORE')
</script>

<template>
    <div class="login-form-bottom">
        <div class="login-form-language-selector">
            <LanguageSelector />
        </div>
        <i18n-t
            v-if="showRegisterLink"
            keypath="login.register.link_to_register"
            tag="div"
            class="login-form-actions"
        >
            <template #register>
                <router-link
                    :to="{ name: 'register' }"
                    class="login-form-actions__link"
                >
                    {{ t('login.register.register') }}
                </router-link>
            </template>
        </i18n-t>
        <i18n-t
            v-if="showRestorePasswordLink"
            id="restore_password"
            keypath="login.restore_password.link_to_restore_password.title"
            tag="div"
            class="login-form-actions"
        >
            <template #restore>
                <router-link
                    :to="{ name: 'restore-password' }"
                    class="login-form-actions__link"
                >
                    {{ t('login.restore_password.link_to_restore_password.link') }}
                </router-link>
            </template>
        </i18n-t>
    </div>
</template>

<style>
    .login-form-bottom {
        margin-block-start: 1em;
        margin-block-end: 3em;
    }

    .login-form-language-selector {
        display: flex;
        justify-content: center;
    }

    .login-form-actions {
        margin-block-end: 1em;
        font-size: 14px;
        text-align: center;
        text-transform: capitalize;

        &__link {
            color: rgb(var(--v-theme-primary));
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: 0.8s;

            &:hover {
                text-decoration-color: rgb(var(--v-theme-primary));
            }
        }
    }
</style>
