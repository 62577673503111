import { defineStore } from '@kidzonet/pinia'
import { notify } from '@kyvg/vue3-notification'

import { getMyIPAddress } from '@kidzonet/ts-api-dashboard-helpers'
import { normalizeIp } from '@kidzonet/use-nets'
import { createInterface, deleteInterface, getInterfacesList } from '@kidzonet/ts-api-dashboard-nets'

interface netItem {
    id: number
    address: string
}

const MAX_NETS_COUNT = 10

export const useNetsStore = defineStore('nets', {
    state: () => {
        const nets: netItem[] = []
        const userId: number | null = null
        return {
            nets,
            loading: false,
            userId,
            myIP: '',
            netError: '',
        }
    },
    getters: {
        userNets: (state): netItem[] => state.nets?.map(({ id, address }) => (
            { id, address: normalizeIp(address) }
        )) || [],
        canUserAddNets: state => MAX_NETS_COUNT > state.nets.length,
        canAddMyIP (state): boolean {
            return !this.userNets.some(({ address }: netItem) => address === state.myIP)
        },
    },
    actions: {
        async fetchInterfacesList () {
            this.loading = true
            const nets = await getInterfacesList() || []
            this.nets = nets
            this.loading = false
        },
        async addNet (address: string) {
            this.loading = true
            this.netError = ''
            try {
                if (this.canUserAddNets) {
                    const id = await createInterface(address)
                    if (id) {
                        await this.fetchInterfacesList()
                    }
                }
            } catch (error: any) {
                if ('detail' in error && Array.isArray(error.detail)) {
                    this.netError = error.detail[0].msg
                }
                if (error && 'status' in error && error.status && [409, 422].includes(error.status)) {
                    this.netError = error.detail
                }
                if (this.netError) {
                    notify({
                        type: 'error',
                        title: this.netError,
                    })
                } else {
                    throw error
                }
            } finally {
                this.loading = false
            }
        },
        async removeNet (net: netItem) {
            this.loading = true
            const result = await deleteInterface(net.id)
            if (result) {
                await this.fetchInterfacesList()
            }
            this.loading = false
        },
        async getMyIP () {
            this.loading = true
            const myIP = await getMyIPAddress()
            this.myIP = myIP
            this.loading = false
        },
    },
})
