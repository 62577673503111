<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDasboardPolicyStore } from '@kidzonet/dashboard-policy-store'
import { useSettingsStore } from '@kidzonet/dashboard-settings-store'

import HolidayTimeIcon from '@kidzonet/dashboard-policy-icons/src/HolidayTime.vue'
import AllowAllIcon from '@kidzonet/dashboard-policy-icons/src/AllowAll.vue'

import PolicyItem from '@kidzonet/dashboard-policy-widget'
import ScheduleExpansionPanel from '@kidzonet/dashboard-schedule'

import RadioIcon from '@kidzonet/dashboard-radio-icon'
import { getPolicyName } from '@kidzonet/use-policy'

const store = useDasboardPolicyStore()

const settingsStore = useSettingsStore()
const { t } = useI18n()

const activate = async (name: string, id?: number) => {
    if (store.activePolicies.some(item => item.id === id)) {
        return
    }
    const promiseList = []
    promiseList.push(store.activatePolicy(id))
    if (name === 'kids_home_alone') {
        promiseList.push(settingsStore.setSettings({ safeSearch: true, safeYoutube: true }))
    } else if (name === 'holiday_time') {
        promiseList.push(settingsStore.setSafeSearch(true))
    }
    await Promise.all(promiseList)
}

const isPoliciesLoaded = computed(() => {
    return store.singlePolicies && store.singlePolicies.length > 0
})

</script>

<template>
    <div
        v-if="isPoliciesLoaded"
        class="policy-options-content"
    >
        <v-tooltip
            :text="t(`policy.${name}.long_description`)"
            v-for="{ name, isActive = false, id } in store.singlePolicies"
            :key="name"
            location="bottom"
            max-width="400"
            :open-on-click="!isActive"
            :open-on-hover="false"
            :close-delay="300"
            :persistent="false"
        >
            <template #activator="{ props }">
                <PolicyItem
                    v-bind="props"
                    :name="name"
                    :id="id"
                    :header="getPolicyName(name)"
                    subheader=""
                    :is-active="isActive"
                    @click="() => activate(name, id)"
                    class="policy-options-content__item"
                >
                    <template #icon>
                        <AllowAllIcon v-if="name === 'allow_all'" />
                        <HolidayTimeIcon v-else-if="name === 'holiday_time'" />
                    </template>
                    <RadioIcon :is-active="isActive" />
                    <template #bottom>
                        <ScheduleExpansionPanel
                            v-if="isActive && id"
                            class="policy-options-content-schedule"
                            :name="name"
                            :id="id"
                        />
                    </template>
                </PolicyItem>
            </template>
        </v-tooltip>
    </div>
</template>

<style>
    .policy-options-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;

        @media(max-width: 800px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }
    .policy-options-content-schedule {
        margin-block-start: 1em;
    }
</style>
