<script setup lang="ts">
import { computed } from 'vue'

interface StatusIconProps {
    status: string
}

const props = defineProps<StatusIconProps>()

const color = computed(() => {
    if (['active', 'allowed_request', 'ALLOWED'].includes(props.status)) {
        return 'green'
    }
    if (['canceled', 'blocked_request', 'BLOCKED'].includes(props.status)) {
        return 'red'
    }
    return 'black'
})
</script>

<template>
    <div
        class="statistic-status-selector-icon"
        :style="{ backgroundColor: color }"
    />
</template>

<style>
    .statistic-status-selector-icon {
        inline-size: 1em;
        block-size: 1em;
        margin-inline-end: 0.5em;
        border-radius: 4px;
    }
</style>
