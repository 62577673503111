<script setup lang="ts">
import { ref } from 'vue'
import { useDisplay } from 'vuetify'

import DarkModeSwitcher from '@kidzonet/main-menu-darkmode-switcher'
import Logo from './Logo.vue'
import MainMenu from './Menu.vue'
import AppBar from './AppBar.vue'
import SimpleUISwitcher from './SimpleUISwitcher.vue'

interface MenuItem {
    title: string
    icon: string
    to: { name: string }
}

interface MenuProps {
    menu: MenuItem[]
}

const props = defineProps<MenuProps>()
const { mdAndUp } = useDisplay()
const drawer = ref(mdAndUp.value)

const openDrawer = () => {
    drawer.value = !drawer.value
}
</script>

<template>
    <div>
        <AppBar
            @open-drawer="openDrawer"
        />
        <v-navigation-drawer
            :permanent="drawer"
            :temporary="!drawer"
            v-model="drawer"
        >
            <Logo
                v-if="mdAndUp"
            />
            <MainMenu
                :menu="props.menu"
                :class="{
                    'dashboard-main-menu-drawer__menu': true,
                    'dashboard-main-menu-drawer__menu--mobile': !mdAndUp,
                }"
            />
            <template #append>
                <div class="dashboard-main-menu-drawer__append">
                    <v-divider />
                    <SimpleUISwitcher />
                    <v-divider />
                    <DarkModeSwitcher />
                </div>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<style>
    .dashboard-main-menu-drawer {
        &__menu {
            &--mobile {
                margin-block-start: 2em;
            }
        }

        &__append {
            margin: 0 1em;
        }
    }
</style>
