<script lang="ts" setup>
import { useTheme } from 'vuetify'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'
import Logo from '@kidzonet/logo'

useLoadLocaleMessages('login')
const theme = useTheme()
</script>

<template>
    <div class="login-layout">
        <div class="login-layout-form">
            <div class="login-layout-form__inner">
                <slot name="logo">
                    <Logo
                        :fill="theme.current.value.colors.primary"
                    />
                </slot>
                <slot name="after-logo" />
                <router-view />
            </div>
        </div>
        <div class="login-layout-image">
            <div class="login-layout-image__overlay" />
            <div class="login-layout-image__image" />
        </div>
    </div>
</template>

<style>
    .login-layout {
        display: flex;
        block-size: 100%;
    }
    .login-layout-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        inline-size: 50%;

        &__inner {
            display: flex;
            flex-direction: column;

            inline-size: 100%;
            max-inline-size: 40em;
            block-size: 100%;
            margin-block-start: 5em;
            padding: 0 2em;

            @media(max-width: 600px) {
                margin-block-start: 2em;
                padding: 0 1em;
            }

            @media(max-height: 900px) {
                margin-block-start: 2em;
            }
        }

        @media(max-width: 600px) {
            inline-size: 100%;
        }
    }
    .login-layout-image {
        inline-size: 50%;

        &__image {
            block-size: 100%;

            background-image: url('@kidzonet/assets/src/new-login/background.jpg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }

        &__overlay {
            position: absolute;

            inline-size: 50%;
            block-size: 100%;

            background-color: #5047B7;
            filter: opacity(0.25);
        }

        @media(max-width: 600px) {
            display: none;
        }
    }
    .webp .login-layout-image__image {
        background-image: url('@kidzonet/assets/src/new-login/background.webp');
    }
</style>
