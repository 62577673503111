<script setup lang="ts">
import { useNetsStore } from '@kidzonet/dashboard-nets-store'

import AddForm from '@kidzonet/dashboard-nets-add-form'
import DynDNSForm from '@kidzonet/dashboard-dyn-dns-form'
import MyIpForm from './MyIpForm.vue'
import NetworksTable from './Table.vue'

const store = useNetsStore()
window.NETS_STORE = store
</script>

<template>
    <div class="network-page">
        <div class="network-page__toolbar">
            <AddForm
                class="kidzonet-dashboard-widget network-page__form"
            />
            <MyIpForm
                v-if="store.canAddMyIP"
                class="kidzonet-dashboard-widget network-page__form"
            />
            <DynDNSForm
                class="kidzonet-dashboard-widget network-page__form"
            />
        </div>
        <NetworksTable />
    </div>
</template>

<style>
    .network-page {
        &__toolbar {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1em;
            margin-block-end: 1em;

            @media(max-width: 900px) {
                grid-template-columns: 1fr;
            }
        }

        &__form {
            flex-grow: 1;
            min-inline-size: 300px;
        }

        &__paginator {
            margin-block-start: 1em;
        }
    }
</style>
