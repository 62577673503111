<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import AnimatedNumber from 'vue3-autocounter'

interface CategoryItemProps {
    catId: number
    requestsCount: number
    color?: string
}

const props = defineProps<CategoryItemProps>()

const { t } = useI18n()
</script>

<template>
    <div class="most-popular-categories-item">
        <div class="most-popular-categories-item-content">
            <h4 class="most-popular-categories-item__header">
                <span class="most-popular-categories-item__header-text">
                    {{ t(`categories.${props.catId}`) }}
                </span>
            </h4>
            <div class="most-popular-categories-item__count font-semibold">
                <AnimatedNumber
                    :start-amount="0"
                    :end-amount="props.requestsCount"
                    :duration="1"
                    autoinit
                />
            </div>
        </div>
        <v-progress-linear
            model-value="40"
            height="2"
            :color="color"
        />
    </div>
</template>

<style>
    .most-popular-categories-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        inline-size: 18em;
        padding: 20px;

        &__header {
            overflow: hidden;

            font-size: 14px;
            font-weight: 400;
            color: rgb(var(--v-theme-subHeader));
            text-overflow: elipsis;
            text-transform: capitalize;
        }
        &__count {
            font-size: 32px;
        }

        @media(max-width: 1200px) {
            padding: 10px;
        }
    }

    .most-popular-categories-item-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        block-size: 100%;
    }
</style>
