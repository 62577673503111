<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import StatusIcon from './Icon.vue'

const { t } = useI18n()
const statuses = computed(() => [
    {
        title: t('user_statistic.filters.allowed_requests'),
        icon: 'allowed_request',
        value: 'ALLOWED',
    }, {
        title: t('user_statistic.filters.blocked_requests'),
        icon: 'blocked_request',
        value: 'BLOCKED',
    },
])
</script>

<template>
    <v-select
        :label="t('common.status')"
        :items="statuses"
        clearable
        autocomplete="off"
        variant="outlined"
        color="primary"
        bg-color="mainContentBackground"
        class="statistic-status-selector"
    >
        <template #selection="{ item }">
            <div
                v-if="item.value"
                class="statistic-status-selector__item"
            >
                <StatusIcon :status="item.raw.icon" />
                <div>
                    {{ item.title }}
                </div>
            </div>
        </template>
        <template #item="data">
            <v-list-item
                v-bind="data.props"
                :key="data.item.value"
                :value="data.item.value"
                title=""
            >
                <div class="statistic-status-selector__item">
                    <StatusIcon :status="data.item.raw.icon" />
                    <div>
                        {{ data.item.title }}
                    </div>
                </div>
            </v-list-item>
        </template>
    </v-select>
</template>

<style>
    .statistic-status-selector {
        &__item {
            display: flex;
            align-items: center;
        }
    }
</style>
