<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoadLocaleMessages } from '@kidzonet/vue-i18n-package'

const { t } = useI18n()

useLoadLocaleMessages('privacy')

const date = computed(() => new Date('2020-05-30').toLocaleDateString())

const email = computed(() => 'privacy@kidzonet.com')
const mailto = computed(() => `mailto:${email.value}`)
</script>

<template>
    <div class="privacy">
        <i18n-t
            keypath="privacy.efective_date"
            tag="div"
            class="privacy__effective-date"
        >
            <template #date>
                {{ date }}
            </template>
        </i18n-t>

        <h1 class="privacy__header">
            {{ t('privacy.part_1.header') }}
        </h1>
        <section class="privacy__section">
            <p>{{ t('privacy.part_1.text_1') }}</p>
            <p>{{ t('privacy.part_1.text_2') }}</p>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_2.header') }}
        </h2>
        <section class="privacy__section">
            <p>{{ t('privacy.part_2.text_1') }}</p>
            <p>{{ t('privacy.part_2.text_2') }}</p>
            <ul class="privacy__list">
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_2_items.item_1') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_2_items.item_2') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_2_items.item_3') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_2_items.item_4') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_2_items.item_5') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_2_items.item_6') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_2_items.item_7') }}
                </li>
            </ul>
            <p>{{ t('privacy.part_2.text_3') }}</p>
            <ul class="privacy__list">
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_3_items.item_1') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_3_items.item_2') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_3_items.item_3') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_2.text_3_items.item_4') }}
                </li>
            </ul>
            <p>{{ t('privacy.part_2.text_4') }}</p>
            <p>{{ t('privacy.part_2.text_5') }}</p>
            <p>{{ t('privacy.part_2.text_6') }}</p>
            <ul class="privacy__list">
                <li class="privacy__list-item">
                    <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                    >
                        {{ t('privacy.part_2.text_6_items.item_1') }}
                    </a>
                </li>
                <li class="privacy__list-item">
                    <a
                        href="https://www.apple.com/legal/privacy/en-ww/"
                        target="_blank"
                    >
                        {{ t('privacy.part_2.text_6_items.item_2') }}
                    </a>
                </li>
            </ul>
            <i18n-t
                keypath="privacy.part_2.text_7"
                tag="p"
            >
                <template #email_address>
                    <a :href="mailto">{{ email }}</a>
                </template>
                <template #cookies_section_name>
                    <a href="#part_9">
                        {{ t('privacy.part_9.header') }}
                    </a>
                </template>
            </i18n-t>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_3.header') }}
        </h2>
        <section class="privacy__section">
            <ul class="privacy__list">
                <li class="privacy__list-item">
                    {{ t('privacy.part_3.items.item_1') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_3.items.item_2') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_3.items.item_3') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_3.items.item_4') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_3.items.item_5') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_3.items.item_6') }}
                </li>
            </ul>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_4.header') }}
        </h2>
        <section class="privacy__section">
            <ul class="privacy__list">
                <i18n-t
                    keypath="privacy.part_4.items.item_1"
                    tag="li"
                    class="privacy__list-item"
                >
                    <template #email_address>
                        <a :href="mailto">{{ email }}</a>
                    </template>
                </i18n-t>
                <li class="privacy__list-item">
                    {{ t('privacy.part_4.items.item_2') }}
                </li>
            </ul>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_5.header') }}
        </h2>
        <section class="privacy__section">
            <p>{{ t('privacy.part_5.text_1') }}</p>
            <ul class="privacy__list">
                <li class="privacy__list-item">
                    {{ t('privacy.part_5.items.item_1') }}
                </li>
                <i18n-t
                    keypath="privacy.part_5.items.item_2"
                    tag="li"
                    class="privacy__list-item"
                >
                    <template #email_address>
                        <a :href="mailto">{{ email }}</a>
                    </template>
                </i18n-t>
            </ul>
            <p>{{ t('privacy.part_5.text_2') }}</p>
            <i18n-t
                keypath="privacy.part_5.text_3"
                tag="p"
            >
                <template #email_address>
                    <a :href="mailto">{{ email }}</a>
                </template>
            </i18n-t>
            <p>{{ t('privacy.part_5.text_4') }}</p>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_6.header') }}
        </h2>
        <section class="privacy__section">
            <p>{{ t('privacy.part_6.text_1') }}</p>
            <p>{{ t('privacy.part_6.text_2') }}</p>
            <ul class="privacy__list">
                <li class="privacy__list-item">
                    {{ t('privacy.part_6.items.item_1') }}
                </li>
                <i18n-t
                    keypath="privacy.part_6.items.item_2"
                    tag="li"
                    class="privacy__list-item"
                >
                    <template #email_address>
                        <a :href="mailto">{{ email }}</a>
                    </template>
                </i18n-t>
                <li class="privacy__list-item">
                    {{ t('privacy.part_6.items.item_3') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_6.items.item_4') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_6.items.item_5') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_6.items.item_6') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_6.items.item_7') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_6.items.item_8') }}
                </li>
            </ul>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_7.header') }}
        </h2>
        <section class="privacy__section">
            <p>{{ t('privacy.part_7.text') }}</p>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_8.header') }}
        </h2>
        <section class="privacy__section">
            <p>{{ t('privacy.part_8.text') }}</p>
        </section>

        <h2
            class="privacy__header"
            id="part_9"
        >
            {{ t('privacy.part_9.header') }}
        </h2>
        <section class="privacy__section">
            <p>{{ t('privacy.part_9.text_1') }}</p>
            <p>{{ t('privacy.part_9.text_2') }}</p>
            <ul class="privacy__list">
                <li class="privacy__list-item">
                    {{ t('privacy.part_9.items.item_1') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_9.items.item_2') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_9.items.item_3') }}
                </li>
                <li class="privacy__list-item">
                    {{ t('privacy.part_9.items.item_4') }}
                </li>
            </ul>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_10.header') }}
        </h2>
        <section class="privacy__section">
            <p>{{ t('privacy.part_10.text') }}</p>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_11.header') }}
        </h2>
        <section class="privacy__section">
            <p>{{ t('privacy.part_11.text') }}</p>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_12.header') }}
        </h2>
        <section class="privacy__section">
            <p>{{ t('privacy.part_12.text') }}</p>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_13.header') }}
        </h2>
        <section class="privacy__section">
            <i18n-t
                keypath="privacy.part_13.text"
                tag="p"
            >
                <template #email_address>
                    <a :href="mailto">{{ email }}</a>
                </template>
            </i18n-t>
        </section>

        <h2 class="privacy__header">
            {{ t('privacy.part_14.header') }}
        </h2>
        <section class="privacy__section">
            <p>{{ t('privacy.part_14.text') }}</p>
        </section>
    </div>
</template>

<style>
    .privacy {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin: 2em;

        &__section {
            margin: 1em 0;
        }

        &__list {
            margin: 0.5em 0;
        }

        &__list-item {
            padding-inline-start: 1em;
        }
    }
</style>
