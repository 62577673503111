<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'

import { useVuelidate } from '@vuelidate/core'
import { email as emailValidator, required } from '@vuelidate/validators'

import { useAuthStore } from '@kidzonet/pinia/UserStore'
import PasswordField from '@kidzonet/password-field'

import BottomContent from './Bottom.vue'
import Checkbox from './Checkbox.vue'
import Captcha from './Captcha.vue'

const router = useRouter()
const { t } = useI18n()
const showErrorMessage = ref(false)
const firstName = ref('')
const lastName = ref('')
const lastNameRef = ref()

const email = ref('')
const emailRef = ref()

const password = ref('')
const passwordRef = ref()

const accepted = ref(false)
const captcha = ref(false)

const store = useAuthStore()

const rules = computed(() => ({
    firstName: {
        required,
    },
    lastName: {
        required,
    },
    email: {
        required,
        emailValidator,
    },
    password: {
        required,
    },
    accepted: {
        isTrue (value: boolean) {
            return value
        },
    },
}))

const $v = useVuelidate(rules, {
    // eslint-disable-next-line ts/prefer-ts-expect-error
    // @ts-ignore strange behavior
    firstName,
    lastName,
    email,
    password,
    accepted,
})
const buttonIsDisabled = computed(() => $v.value.$invalid || !captcha.value)

const pressEnterOnFirstNameField = () => {
    lastNameRef.value.focus()
}
const pressEnterOnLastNameField = () => {
    emailRef.value.focus()
}
const pressEnterOnEmailField = () => {
    passwordRef.value.focus()
}

const signup = async () => {
    if (!buttonIsDisabled.value) {
        showErrorMessage.value = false
        const resp = await store.registerUser({
            email: email.value,
            password: password.value,
            username: email.value,
            first_name: firstName.value,
            last_name: lastName.value,
        })
        if (resp === true) {
            router.push({ name: 'home' })
        } else if (resp?.status === 409) {
            showErrorMessage.value = true
        } else {
            notify({
                type: 'error',
                title: t('errors.server_error'),
            })
        }
    }
}

const hideErrorMessage = () => {
    showErrorMessage.value = false
}
</script>

<template>
    <div
        class="register-form"
    >
        <v-text-field
            autofocus
            :label="t('login.first_name')"
            v-model.trim="firstName"
            autocapitalize="none"
            variant="outlined"
            @keyup.enter="pressEnterOnFirstNameField"
            color="primary"
        />
        <v-text-field
            :label="t('login.last_name')"
            v-model.trim="lastName"
            ref="lastNameRef"
            autocapitalize="none"
            variant="outlined"
            @keyup.enter="pressEnterOnLastNameField"
            color="primary"
        />
        <v-text-field
            :label="t('login.email')"
            v-model.trim="email"
            ref="emailRef"
            autocapitalize="none"
            variant="outlined"
            @keyup.enter="pressEnterOnEmailField"
            @update:model-value="hideErrorMessage"
            color="primary"
        />
        <PasswordField
            ref="passwordRef"
            v-model.trim="password"
            @update:model-value="hideErrorMessage"
        />
        <Checkbox v-model="accepted" />
        <div
            class="register-form__error"
        >
            <span v-if="showErrorMessage">
                {{ t('login.username_already_exists') }}
            </span>
        </div>
        <Captcha
            v-model="captcha"
            @submit="signup"
        />
        <v-btn
            id="sign_in_button"
            class="register-form__button"
            @click="signup"
            :disabled="buttonIsDisabled"
            color="primary"
        >
            {{ t('login.register.button') }}
        </v-btn>
        <v-spacer />
        <BottomContent />
    </div>
</template>

<style>
    .register-form  {
        display: flex;
        flex-direction: column;
        block-size: 100%;

        &__button {
            inline-size: 100%;
            block-size: 3.5em !important;
            margin-block-start: 2em;

            font-size: 16px;

            border-radius: 8px;

            @media(max-width: 600px) {
                margin-block-start: 1em;
            }

            @media(max-height: 900px) {
                margin-block-start: 1em;
            }
        }

        &__error {
            block-size: 1em;
            color: rgb(var(--v-theme-error));
            text-align: center;
        }

        .v-input {
            flex: none;
        }
    }
</style>
