<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@kidzonet/pinia/UserStore'

const { t } = useI18n()
const store = useAuthStore()
</script>

<template>
    <div class="kidzonet-dashboard-widget my-info-page">
        <div class="my-info-page__content">
            <v-text-field
                :label="t('dashboard_v2.settings_page.full_name')"
                :model-value="store.fullname || store.user.username"
                autocapitalize="none"
                variant="outlined"
                disabled
                class="my-info-page__input"
                color="grey-darken-4"
            />
            <v-text-field
                :label="t('dashboard_v2.settings_page.email_address')"
                :model-value="store.user.email"
                autocapitalize="none"
                variant="outlined"
                color="primary"
                disabled
                class="my-info-page__input"
            />
            <v-text-field
                :label="t('dashboard_v2.settings_page.user_id')"
                :model-value="store.user.id"
                autocapitalize="none"
                variant="outlined"
                color="primary"
                disabled
                class="my-info-page__input"
            />
        </div>
    </div>
</template>

<style>
    .my-info-page {
        min-block-size: 80vh;
        padding: 5em 10em !important;

        &__content {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;
        }

        &__input {
            flex-grow: 0;
            inline-size: 45%;

            * {
                opacity: 0.85;
            }

            @media(max-width: 1100px) {
                inline-size: 100%;
            }
        }

        @media(max-width: 1100px) {
            padding: 5em 2em !important;
        }
    }
</style>
