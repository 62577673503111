<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useStatsStore } from '@kidzonet/dashboard-stats-store'
import AnimatedNumber from 'vue3-autocounter'

import Chart from './Chart.vue'

const { t } = useI18n()
const store = useStatsStore()
</script>

<template>
    <div class="overview-today-request">
        <h3 class="overview-header-2 font-medium">
            {{ t('dashboard_v2.overview.todays_requests') }}
        </h3>
        <v-progress-linear
            v-if="store.loading"
            indeterminate
            color="primary"
        />
        <div
            class="overview-today-request__count font-semibold"
        >
            <span v-if="store.loading">
                &nbsp;
            </span>
            <AnimatedNumber
                v-else
                :start-amount="0"
                :end-amount="store.total"
                :duration="1"
                autoinit
            />
        </div>
        <Chart class="overview-today-request__chart" />
    </div>
</template>

<style>
    .overview-today-request {
        &__count {
            margin: 0.7em 0;
            font-size: 32px;
        }

        &__chart {
            @media(max-width: 500px) {
                display: none;
            }
        }
    }
</style>
